
  import React, { useState } from "react";
import emailjs from "emailjs-com";


function Home() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    option: "",
    message: "",
  });

  const [statusMessage, setStatusMessage] = useState(null);
  const [messageColor, setMessageColor] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // First email
    emailjs
      .send(
        "service_v5mteyt", // Replace with your EmailJS Service ID
        "template_g47u7v8", // Replace with your EmailJS Template ID
        {
          name: formData.name,
          email: formData.email,
          subject: formData.subject,
          option: formData.option,
          message: formData.message,
        },
        "MK3084LD_haZiyjEi" // Replace with your EmailJS User ID
      )
      .then(
        (response) => {
          console.log("Email 1 SUCCESS!", response);
  
          // Send to second email after the first succeeds
          emailjs
            .send(
              "service_v5mteyt", // Same or another service ID
              "template_d1kmxgr", // Same or another template ID
              {
                name: formData.name,
                email: formData.email,
                subject: formData.subject,
                option: formData.option,
                message: formData.message,
              },
              "MK3084LD_haZiyjEi" // Same user ID
            )
            .then(
              (response) => {
                console.log("Email 2 SUCCESS!", response);
                setStatusMessage("Message sent successfully!");
                setMessageColor("green");
                setFormData({
                  name: "",
                  email: "",
                  subject: "",
                  option: "",
                  message: "",
                });
                // Hide the message after 3 seconds
                setTimeout(() => {
                  setStatusMessage(null);
                }, 3000);
              },
              (error) => {
                console.error("Email 2 FAILED...", error);
                setStatusMessage(
                  "Message sent to the first email but failed to send to the second email."
                );
                setMessageColor("orange");
                setTimeout(() => {
                  setStatusMessage(null);
                }, 3000);
              }
            );
        },
        (error) => {
          console.error("Email 1 FAILED...", error);
          setStatusMessage("Failed to send message. Please try again.");
          setMessageColor("red");
          setTimeout(() => {
            setStatusMessage(null);
          }, 3000);
        }
      );
  };
  
  
  
  return (
    <div id="body" className="tt-transition tt-boxed tt-smooth-scroll tt-magic-cursor">
    
    <div id="body-inner">
    {/* Begin page transition (do not remove!!!) 
        =========================== */}
    <div id="page-transition">
      <div className="ptr-overlay" />
      <div className="ptr-preloader">
        <div className="ptr-prel-content">
          {/* Hint: You may need to change the img height and opacity to match your logo type. You can do this from the "theme.css" file (find: ".ptr-prel-image"). */}
          <img
            src="assets/img/logo-light.png"
            className="ptr-prel-image tt-logo-light"
            alt="Logo"
          />
        </div>{" "}
        {/* /.ptr-prel-content */}
      </div>{" "}
      {/* /.ptr-preloader */}
    </div>
    {/* End page transition */}
    {/* Begin magic cursor 
        ======================== */}
    <div id="magic-cursor">
      <div id="ball" />
    </div>
    {/* End magic cursor */}
    {/* Begin noise background */}
    <div className="bg-noise" />
    {/* End noise background */}
    {/* *****************************************
     *********** Begin scroll container ***********
     ****************************************** */}
    <div id="scroll-container">
      {/* ===================
          ///// Begin header /////
          ========================
          * Use class "tt-header-fixed" to set header to fixed position.
        
  
          
          *************************************
          *********** Begin content wrap *********** 
          ************************************** */}
      <div id="content-wrap">
        {/* ========================
            ///// Begin page header /////
            ============================= 
            * ATENTION: This page header is slightly different from the default page header! 
            It contains some extra elements and classec specifically for a blog post.
            ============================= 
            * Use class "ph-full" to enable fullscreen size.
            * Use class "ph-cap-sm", "ph-cap-lg", "ph-cap-xlg" or "ph-cap-xxlg" to set caption size (no class = default size).
            * Use class "ph-center" to enable content center position.
            * Use class "ph-bg-image" to enable page header background image (required for a portfolio single project!).
            * Use class "ph-bg-image-is-light" if needed, it makes the elements dark and more visible if you use a very light image (effect only with class "ph-bg-image").
            * Use class "ph-image-shadow" to enable page header background image (effect only with class "ph-bg-image").
            * Use class "ph-image-cropped" to crop image. It fixes image dimensions (no effect for "ph-bg-image"!).
            * Use class "ph-image-cover-*" to set image overlay opacity. For example "ph-image-cover-2" or "ph-image-cover-2-5" (up to "ph-image-cover-9-5").
            * Use class "ph-content-parallax" to enable content parallax.
            * Use class "ph-stroke" to enable caption title stroke style.
            */}
        <div
          id="page-header"
          className="ph-full ph-cap-sm ph-center ph-bg-image ph-image-shadow ph-image-cover-6 ph-content-parallax"
        >
          <div className="page-header-inner tt-wrap max-width-1400">
            {/* Begin page header image 
                ============================= */}
            <div className="ph-image">
              <div className="ph-image-inner">
                <img
                  src="assets/img/R.jpeg"
                  alt="Image"
                />
              </div>
            </div>
            {/* End page header image */}
            {/* Begin page header caption
                =============================== 
                Use class "max-width-*" to set caption max width if needed. For example "max-width-1000". More info about helper classes can be found in the file "helper.css".
                */}
            <div className="ph-caption">
              <div className="ph-categories ph-appear">
                <a  className="ph-category">
                  COMING SOON
                </a>
                {/* <a href="blog-archive.html" class="ph-category">Uncategorized</a> */}
              </div>{" "}
              {/* /.ph-categories */}
              <h2 className="ph-caption-title ph-appear">
                Still Progressing Behind the Scenes—Creativity Takes Time!
              </h2>{" "}
              {/* You can use <br class="hide-from-lg"> to break a text line if needed */}
              <div className="ph-meta ph-appear">
                <span className="ph-meta-published">Scribble DM</span>
              </div>{" "}
              {/* /.ph-meta */}
            </div>
            {/* End page header caption */}
          </div>{" "}
          {/* /.page-header-inner */}
          {/* Begin scroll down circle (you can change "data-offset" to set scroll top offset) 
              ============================== */}
          <a href="#page-content" className="scroll-down-circle" data-offset={30}>
            <div className="sdc-inner ph-appear">
              <div className="sdc-icon">
                <i className="fas fa-chevron-down" />
              </div>
              <svg viewBox="0 0 500 500">
                <defs>
                  <path
                    d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250"
                    id="textcircle"
                  />
                </defs>
                <text dy={30}>
                  <textPath xlinkHref="#textcircle">
                    Scroll down - Scroll down -
                  </textPath>
                </text>
              </svg>
            </div>{" "}
            {/* /.sdc-inner */}
          </a>
          {/* End scroll down circle */}
          {/* Begin made with love 
              ========================== */}
        
          {/* End made with love */}
          {/* Begin page header share (share buttons are for design purposes only!) 
              ============================= */}
        
          {/* End page header share */}
        </div>
        {/* End page header */}
        {/* *************************************
         *********** Begin page content ***********
         ************************************** */}
        <div id="page-content">
          {/* =======================
              ///// Begin tt-section /////
              ============================ 
              * You can use padding classes if needed. For example "padding-top-xlg-150", "padding-bottom-xlg-150", "no-padding-top", "no-padding-bottom", etc. Note that each situation may be different and each section may need different classes according to your needs. More info about helper classes can be found in the file "helper.css".
              */}
          {/* End tt-section */}
          {/* =======================
              ///// Begin tt-section /////
              ============================ 
              * You can use padding classes if needed. For example "padding-top-xlg-150", "padding-bottom-xlg-150", "no-padding-top", "no-padding-bottom", etc. Note that each situation may be different and each section may need different classes according to your needs. More info about helper classes can be found in the file "helper.css".
              */}
          {/* End tt-section */}
        </div>
        {/* End page content */}
        {/* ======================
            ///// Begin tt-footer /////
            =========================== */}
        {/* End tt-footer */}
      </div>
      {/* End content wrap */}
    </div>
    <div className="tt-section">
              <div className="tt-section-inner tt-wrap">
                <div className="tt-row">
                  <div className="tt-col-lg-5 padding-right-lg-5-p">
                    {/* Begin tt-Heading 
                      ====================== 
                      * Use class "tt-heading-xsm", "tt-heading-sm", "tt-heading-lg", "tt-heading-xlg" or "tt-heading-xxlg" to set caption size (no class = default size).
                      * Use class "tt-heading-stroke" to enable stroke style.
                      * Use class "tt-heading-center" to align tt-Heading to center.
                      * Use prepared helper class "max-width-*" to add custom width if needed. Example: "max-width-800". More info about helper classes can be found in the file "helper.css".
                      */}
                    <div className="tt-heading tt-heading-lg margin-bottom-10-p anim-fadeinup">
                      <h3 className="tt-heading-subtitle text-gray">
                        Get in Touch
                      </h3>
                      <h2 className="tt-heading-title">
                        Drop Us
                        <br /> A Line
                      </h2>
                    </div>
                    {/* End tt-Heading */}
                    {/* <div class="text-gray padding-bottom-40 anim-fadeinup">
                        <p>Lorem, ipsum, dolor sit amet consectetur adipisicing elit. Molestias assumenda, ratione voluptatibus. Libero nisi laudantium.</p>
                      </div> */}
                    {/* Begin contact info 
                      ======================== */}
                    <ul className="tt-contact-info padding-bottom-40 text-gray">
                      <li className="anim-fadeinup">
                        <span className="tt-ci-icon">
                          <i className="fas fa-map-marker-alt" />
                        </span>
                       
Sheraton Heliopolis, Cairo, Egypt
                      </li>
                      <li className="anim-fadeinup">
                        <span className="tt-ci-icon">
                          <i className="fas fa-phone" />
                        </span>
                        <a href="tel:+201221669876" className="tt-link">
                        +20 1221669876
                        </a>
                       
                      </li>
                    
                      
                      <li className="anim-fadeinup">
                        <span className="tt-ci-icon">
                          <i className="fas fa-envelope" />
                        </span>
                        <a href="mailto:contact@scribble-mena.com" className="tt-link">
                        contact@scribble-mena.com
                        </a>
                      </li>
                      <li className="anim-fadeinup">
                        <h6 className="no-margin-bottom margin-top-40">
                          Follow:
                        </h6>
                        {/* Begin social buttons */}
                        <div className="social-buttons">
                          <ul>
                            <li>
                              <a
                                href="https://www.facebook.com/ScribbleDM"
                                className="magnetic-item"
                                target="_blank"
                                rel="noopener"
                              >
                                Fb.
                              </a>
                            </li>
                            <li>
                        <a
                          href="https://www.instagram.com/scribble.dm/"
                          className="magnetic-item"
                          target="_blank"
                          rel="noopener"
                        >
                          Ig.
                        </a>
                      </li>
                            <li>
                              <a
                                href="https://www.tiktok.com/@scribbledm?_t=8s5xlqnWwxI&_r=1"
                                className="magnetic-item"
                                target="_blank"
                                rel="noopener"
                              >
                                Tt.
                              </a>
                            </li>
                           
                            <li>
                              <a
                                href="https://vimeo.com/channels/scribbledm"
                                className="magnetic-item"
                                target="_blank"
                                rel="noopener"
                              >
                                Vi.
                              </a>
                            </li>
                         
                          </ul>
                        </div>
                        {/* End social buttons */}
                      </li>
                    </ul>
                    {/* End contact info */}
                  </div>{" "}
                  {/* /.tt-col */}
                  <div className="tt-col-lg-7">
                      <form
                        id="tt-contact-form"
                        className="tt-form-minimal anim-fadeinup"
                        onSubmit={handleSubmit}
                      >
                        <div className="tt-row">
                          <div className="tt-col-md-6">
                            <div className="tt-form-group">
                              <label>
                                Name <span className="required">*</span>
                              </label>
                              <input
                                className="tt-form-control"
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="tt-col-md-6">
                            <div className="tt-form-group">
                              <label>
                                Email address <span className="required">*</span>
                              </label>
                              <input
                                className="tt-form-control"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="tt-form-group">
                          <label>
                            Subject <span className="required">*</span>
                          </label>
                          <input
                            className="tt-form-control"
                            type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="tt-form-group">
                          <label>
                            Select an option <span className="required">*</span>
                          </label>
                          <select
                            className="tt-form-control"
                            name="option"
                            value={formData.option}
                            onChange={handleChange}
                            required
                          >
                            <option value="" disabled>
                              Please choose an option
                            </option>
                            <option value="Say Hello">Say hello</option>
                            <option value="New Project">New project</option>
                            <option value="Feedback">Feedback</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        <div className="tt-form-group">
                          <label>
                            Message <span className="required">*</span>
                          </label>
                          <textarea
                            className="tt-form-control"
                            rows={6}
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                        <small className="tt-form-text">
                          <em>Fields marked with an asterisk (*) are required!</em>
                        </small>
                        <div className="tt-btn tt-btn-light-outline margin-top-40">
                          <button type="submit">Send Message</button>
                        </div>
                        {statusMessage && (
                          <p style={{ color: messageColor }}>{statusMessage}</p>
                        )}
                      </form>
                    </div>{" "}
                  {/* /.tt-col */}
                </div>{" "}
                {/* /.tt-row */}
              </div>{" "}
              {/* /.tt-section-inner */}
            </div>
    {/* End scroll container */}
  </div>
  </div>
  
  
  
  )
}

export default Home